/************************/
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .header__bottom_block a.compare-total-container {
    margin-right: 30px; }
  .header__top_block .search {
    width: 28%; }
  .header__bottom_block .right_col button.open_search {
    margin-right: 30px; }
  .box_html_38 .box_content .item,
  .box_html_39 .box_content .item {
    padding-left: 60px;
    padding-right: 60px; } }

/*@media screen and (min-width: 992px) and (max-width: 1199px){*/
@media screen and (min-width: 992px) and (max-width: 1026px) {
  .header__top_block > .container .header___info_menu ul li {
    margin-left: 30px; }
  .header__top_block > .container .right_col .private_area button {
    margin-left: 30px; }
  .header__bottom_block .left_col .bottom_info_menu {
    margin-left: 60px; } }

/*@media screen and (min-width: 992px) and (max-width: 1026px){*/
@media screen and (max-width: 991px) {
  .container {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px; }
  .row {
    margin-right: -15px;
    margin-left: -15px; }
  .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9 {
    padding-right: 15px;
    padding-left: 15px; }
  header {
    display: none; }
  #mobile_menu_header {
    display: flex !important;
    position: fixed;
    width: 100%;
    height: 64px;
    align-items: center;
    justify-content: space-between;
    z-index: 1001;
    background-color: #ffffff;
    padding-left: 20px;
    padding-right: 20px;
    border-bottom: solid 1px #E9E9E9; }
  #mobile_menu_header .mobile_menu_button {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center; }
  #mobile_menu_header .mobile_menu_button .icon {
    font-size: 22px;
    color: #0D0E0F; }
  #mobile_menu_header .mobile_menu_button:hover .icon {
    color: #EC5949; }
  #mobile_menu_header .mobile_open_search {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center; }
  #mobile_menu_header .mobile_open_search .icon {
    font-size: 24px;
    color: #0D0E0F; }
  #mobile_menu_header .mobile_open_search.active .icon {
    opacity: 0.1; }
  #mobile_menu_header .search {
    position: absolute;
    top: 62px;
    width: 100%;
    left: 0;
    border-top: solid 1px #E9E9E9;
    opacity: 0;
    z-index: -1;
    visibility: hidden; }
  #mobile_menu_header .search.active {
    opacity: 1;
    z-index: 1;
    visibility: visible; }
  #mobile_menu_header .search input {
    border: none;
    padding-top: 20px;
    padding-bottom: 20px; }
  #mobile_menu_header .search button.search-button {
    top: 16px;
    right: 20px; }
  /***********/
  .maincontent {
    margin-top: 64px; }
  .box {
    padding-top: 60px; }
  footer {
    margin-top: 60px; }
  .footer__top_block {
    padding: 30px 0; }
  .footer__top_block .row {
    margin-left: 0;
    margin-right: 0; }
  .footer__top_block .row .col {
    border-bottom: solid 1px #E9E9E9;
    padding: 15px 0px !important; }
  .footer__top_block .row .col:last-child {
    border-bottom: none; }
  .footer__top_block .col_title {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 0; }
  .footer__top_block .col_title button {
    margin-left: auto;
    display: flex !important;
    width: 16px;
    height: 16px;
    align-items: center;
    justify-content: center; }
  .footer__top_block .col_title button .icon {
    color: #E9E9E9;
    font-size: 14px; }
  .footer__top_block .col_content {
    max-height: 0px;
    overflow: hidden;
    transition: max-height 0.15s ease-out;
    position: relative; }
  .footer__top_block .col.active .col_content {
    max-height: 500px;
    transition: max-height 0.25s ease-in; }
  .footer__top_block .col.active .col_title button.toggleButton {
    transform: rotate(90DEG); }
  .footer__top_block ul {
    margin-top: 10px !important; }
  .footer__top_block .socials {
    margin-top: 20px; }
  /***************/
  .footer__bottom_block .row {
    display: flex;
    align-items: center;
    justify-content: flex-start; }
  .footer__bottom_block .row > div:last-child {
    margin-left: auto; }
  .footer__bottom_block a.wss {
    display: flex;
    justify-content: flex-end; }
  .footer__bottom_block a.wss .text {
    min-width: auto; }
  /**********************************/
  #mobile_menu_content {
    position: fixed;
    background-color: #ffffff;
    top: 64px;
    left: -110%;
    width: 100%;
    height: calc(100vh - 64px);
    z-index: 1000;
    display: block !important; }
  #mobile_menu_content.active {
    left: 0; }
  /**********************************************************/
  .menus_container {
    position: relative;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 10px; }
  #cat_menu ul {
    margin: 0;
    padding: 0;
    list-style: none; }
  #cat_menu > ul > li {
    position: relative;
    padding: 15px 0px; }
  #cat_menu > ul > li > .text,
  #cat_menu > ul > li > a {
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 23px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #0D0E0F;
    text-decoration: none; }
  #cat_menu > ul > li > a:hover {
    color: #EC5949; }
  #cat_menu ul > li > .text > .icon {
    display: flex;
    width: 18px;
    height: 18px;
    align-items: center;
    justify-content: center;
    margin-left: auto; }
  #cat_menu ul > li > .text > .icon:before {
    font-size: 16px;
    color: #E9E9E9; }
  #cat_menu > ul > li > .sub_menu_container {
    display: none; }
  #cat_menu > ul > li > .sub_menu_container {
    display: block;
    position: fixed;
    top: 64px;
    left: -100%;
    width: 100%;
    height: calc(100vh - 64px);
    overflow: auto;
    padding-bottom: 15px;
    z-index: 2;
    background-color: #ffffff;
    transition-duration: 0.2s;
    transition-property: all;
    transition-timing-function: linear;
    padding-left: 15px;
    padding-right: 15px; }
  #cat_menu > ul > li > .sub_menu_container.active {
    left: 0; }
  #cat_menu > ul > li > .sub_menu_container > ul > li {
    position: relative; }
  #cat_menu .next_level_button {
    position: absolute;
    right: 10px;
    top: 17px;
    display: flex;
    width: 18px;
    height: 18px;
    align-items: center;
    justify-content: center; }
  #cat_menu .next_level_button .icon {
    font-size: 16px;
    color: #E9E9E9; }
  /***********/
  button.next_level_button_first:hover .icon {
    color: #EC5949 !important; }
  li.has_child .next_level_button.active .icon {
    color: #EC5949 !important; }
  /*********/
  li.has_child .next_level_button.active {
    transform: rotate(90DEG) !important; }
  li.has_child .next_level_button.active .icon {
    color: #EC5949 !important; }
  /**************/
  #cat_menu > ul > li > .sub_menu_container > ul > li > a {
    display: block;
    font-family: Gilroy;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 23px;
    color: #262A2D;
    text-decoration: none;
    padding: 24px 30px 24px 0px;
    border-bottom: solid 1px #E9E9E9; }
  /********************/
  .sub_menu_container_level2 {
    max-height: 0px;
    overflow: hidden;
    transition: max-height 0.15s ease-out;
    position: relative; }
  .sub_menu_container_level2.active {
    max-height: 3500px;
    transition: max-height 0.25s ease-in; }
  .sub_menu_container_level2 > ul > li {
    position: relative;
    border-bottom: solid 1px #E9E9E9; }
  .sub_menu_container_level2 > ul > li:first-child {
    border-top: none; }
  .sub_menu_container_level2 > ul > li > a {
    display: inline-block;
    font-family: Gilroy;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 23px;
    color: #262A2D;
    padding: 25px 30px 25px 30px;
    text-decoration: none; }
  .sub_menu_container_level2 > ul > li > a:hover {
    color: #EC5949; }
  /********************/
  .sub_menu_container_level3 {
    max-height: 0px;
    overflow: hidden;
    transition: max-height 0.15s ease-out;
    position: relative;
    padding-left: 30px; }
  .sub_menu_container_level3.active {
    max-height: 2500px;
    transition: max-height 0.25s ease-in; }
  .sub_menu_container_level3 ul li a {
    font-family: Gilroy;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    color: #262A2D;
    text-decoration: none;
    display: block;
    padding: 9px 0px; }
  .sub_menu_container_level3 ul li a:hover {
    color: #EC5949; }
  /*********************/
  .sub_menu_container > ul.level_3 {
    padding-bottom: 25px !important; }
  /******************************************/
  #info_menu {
    margin-top: 15px; }
  #info_menu ul {
    margin: 0;
    padding: 0;
    list-style: none; }
  #info_menu ul li {
    margin-top: 30px; }
  #info_menu ul li:first-child {
    margin-top: 0px; }
  #info_menu ul li a {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0.04em;
    color: #0D0E0F;
    text-decoration: none; }
  #info_menu ul li a:hover {
    color: #EC5949; }
  /***********************/
  #mobile_menu_content .contacts_block {
    margin-top: 15px;
    margin-left: 25px;
    margin-right: 25px;
    padding: 25px 0px;
    border-top: solid 1px #E9E9E9;
    border-bottom: solid 1px #E9E9E9; }
  #mobile_menu_content .contacts_block a.telephone {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-decoration: none; }
  #mobile_menu_content .contacts_block a.telephone .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    font-size: 18px;
    color: #ED6A5B;
    margin-right: 10px; }
  #mobile_menu_content .contacts_block a.telephone .text {
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.04em;
    color: #0D0E0F; }
  #mobile_menu_content .contacts_block a.telephone .text:hover {
    color: #EC5949; }
  /**********/
  #mobile_menu_content .private_area {
    margin-top: 30px; }
  #mobile_menu_content .private_area button {
    display: flex;
    align-items: center;
    justify-content: flex-start; }
  #mobile_menu_content .private_area button .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    font-size: 18px;
    color: #ED6A5B;
    margin-right: 10px; }
  #mobile_menu_content .private_area button .text {
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.04em;
    color: #262A2D;
    margin-top: 2px; }
  /********************/
  #mobile_menu_content .language_container {
    padding: 25px; }
  #mobile_menu_content .language_container button {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #0D0E0F; }
  #mobile_menu_content .language_container button:hover {
    color: #0D0E0F; }
  #mobile_menu_content .language_container button:first-child {
    margin-right: 24px; }
  #mobile_menu_content .language_container button.active {
    color: #9AA5AE; }
  /*******************/
  .mobile_menu_button_close {
    display: flex;
    width: 18px;
    height: 18px;
    align-items: center;
    justify-content: center; }
  .mobile_menu_button_close .icon {
    font-size: 18px;
    color: #16181A; }
  /**********/
  .mobile_menu_button_back {
    width: 24px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center; }
  .mobile_menu_button_back {
    font-size: 15px;
    color: #16181A; }
  #cat_menu > ul > li > .sub_menu_container > ul > li > .next_level_button {
    top: 25px; }
  /**************/
  .box_html_38,
  .box_html_39 {
    padding-bottom: 40px; }
  .box_html_38 .box_content,
  .box_html_39 .box_content {
    justify-content: space-between; }
  .box_html_38 .box_content .item,
  .box_html_39 .box_content .item {
    padding-left: 30px;
    padding-right: 30px; } }

/*@media screen and (max-width: 991px){*/
@media screen and (max-width: 767px) {
  .menus_container {
    padding-left: 15px;
    padding-right: 15px; }
  #cat_menu > ul > li {
    padding: 10px 0; }
  #cat_menu > ul > li > .text, #cat_menu > ul > li > a {
    font-size: 16px;
    line-height: 20px; }
  #cat_menu .next_level_button {
    right: 5px;
    top: 10px; }
  #cat_menu > ul > li > .sub_menu_container > ul > li > .next_level_button {
    top: 17px; }
  .sub_menu_container_level2 > ul > li > a {
    padding-top: 15px;
    padding-bottom: 15px; }
  .sub_menu_container_level2 > ul > li .next_level_button {
    top: 16px !important; }
  /************/
  #info_menu {
    margin-top: 10px; }
  #info_menu ul li {
    margin-top: 20px; }
  /**************/
  #mobile_menu_content .contacts_block {
    margin-left: 15px;
    margin-right: 15px;
    padding: 15px 0; }
  /***************/
  #mobile_menu_content .language_container {
    padding: 15px; }
  /***************/
  #cat_menu > ul > li > .sub_menu_container > ul > li > a {
    padding-top: 15px;
    padding-bottom: 15px; }
  /**********************/
  .custom_navigation_container .item {
    height: 3px; }
  .custom_navigation_container .item .counter {
    display: none !important; }
  .custom_navigation_container .item .text {
    width: 60px;
    padding-bottom: 0 !important; }
  .custom_navigation_container .item .text .name {
    display: none; }
  .box {
    padding-top: 40px; }
  .box_heading {
    margin-bottom: 20px;
    font-size: 24px !important;
    line-height: 30px !important; }
  /**********************************/
  ul.slick-dots {
    margin-top: 30px;
    padding-left: 0;
    width: 100%;
    text-align: center;
    margin-bottom: 0;
    height: 3px; }
  ul.slick-dots li {
    display: inline-block;
    margin: 0 5px;
    height: 3px; }
  ul.slick-dots li button {
    -webkit-appearance: none;
    box-shadow: none;
    -webkit-box-shadow: none;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
    -webkit-appearance: none;
    outline: none;
    border: none;
    background: none;
    margin: 0;
    padding: 0;
    text-decoration: none;
    transition-duration: 0.2s;
    transition-property: all;
    transition-timing-function: linear; }
  ul.slick-dots li button {
    position: relative;
    /*width: 60px;*/
    width: 30px;
    height: 3px;
    position: relative;
    text-indent: -9999px; }
  ul.slick-dots li button:before {
    content: '';
    width: 100%;
    height: 3px;
    background-color: #E9E9E9;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1; }
  ul.slick-dots li.slick-active button:after {
    content: '';
    /*width: 60px;*/
    width: 30px;
    height: 3px;
    background-color: #ED6A5B;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 2; }
  /****************/
  .box_about_company .box_content {
    max-height: 390px; }
  /***********************************/
  footer {
    margin-top: 40px; }
  .box_newsletters {
    padding: 40px 0px; }
  .box_newsletters .title {
    padding-bottom: 20px;
    font-size: 24px !important;
    line-height: 30px !important;
    text-align: center !important; }
  .box_newsletters .content_col {
    padding-bottom: 15px; }
  /***********/
  .footer__top_block {
    padding-top: 15px;
    padding-bottom: 40px; }
  .footer__bottom_block a.wss .text {
    display: none; }
  .info_slide {
    min-width: 100% !important;
    max-width: 100% !important;
    padding: 20px;
    top: 0 !important;
    height: 100% !important;
    overflow-x: hidden !important; }
  #add_review_slide {
    top: 54px;
    height: calc(100vh - 54px); }
  /*******************/
  .box_html_38,
  .box_html_39 {
    padding-bottom: 0;
    margin-top: 40px !important; }
  .box_html_39 {
    margin-top: 0px !important; }
  .box_html_38 .box_heading,
  .box_html_39 .box_heading {
    margin-bottom: 0 !important; }
  .box_html_38 .box_content,
  .box_html_39 .box_content {
    display: block; }
  .box_html_38 .box_content .item,
  .box_html_39 .box_content .item {
    padding-top: 40px;
    padding-bottom: 40px;
    border-left: none !important;
    border-top: solid 1px #E9E9E9 !important;
    text-align: center; }
  .box_html_38 .box_content .item:first-child,
  .box_html_39 .box_content .item:first-child {
    border-top: none !important; }
  .box_html_38 .box_content .item .title,
  .box_html_39 .box_content .item .title {
    margin-top: 20px;
    font-size: 16px;
    line-height: 22px; }
  .box_html_38 .box_content .item .item_content,
  .box_html_39 .box_content .item .item_content {
    width: 100%;
    margin-right: 0;
    margin-left: 0;
    text-align: center;
    max-width: 100%; }
  .box_html_38 .box_content .item .item_content a,
  .box_html_39 .box_content .item .item_content a {
    display: inline-block; }
  .box_html_38 .box_content .item .item_content,
  .box_html_38 .box_content .item .item_content a,
  .box_html_39 .box_content .item .item_content,
  .box_html_39 .box_content .item .item_content a {
    font-size: 16px;
    line-height: 22px; }
  .box_html_38 .box_content .item:last-child .item_content,
  .box_html_39 .box_content .item:last-child .item_content {
    display: inline-block;
    max-width: 182px; }
  /*************************************************************/
  .box_contacts {
    padding-bottom: 40px; }
  .box_contacts .grid_block {
    grid-template-columns: repeat(1, minmax(100px, 1fr)); }
  .box_contacts .grid_block .item {
    border: none !important;
    border-top: solid 1px #e9e9e9 !important; }
  .box_contacts .grid_block .item:first-child {
    border-top: none !important; } }

/*@media screen and (max-width: 767px){*/
